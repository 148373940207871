import React, { useEffect, useState } from "react";

import { Button, FormControlLabel, Switch } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { Link } from "gatsby";
import moment from "moment";

import DashboardGrid from "~/components/Appointments/DashboardGrid";
import PrivateRoute from "~/components/Authentication/PrivateRoute";
import LoadingError from "~/components/Loaders/LoadingError";
import api from "~/utils/api/api";
import appointmentService from "~/utils/api/v1/appointmentService";
import patientService from "~/utils/api/v1/patientService";
import { calculateAge } from "~/utils/dates/formatDate";
import Appointment from "~/utils/interfaces/Appointment";
import { User, baseUser } from "~/utils/interfaces/User";

interface Props {
  id: string;
}

const IndexPage = ({ id }: Props): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>({});
  const [patient, setPatient] = useState<User>(baseUser);
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [nextRequest, setNextRequest] = useState<string | null>(null);
  const [prevRequest, setPrevRequest] = useState<string | null>(null);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [sms, setSms] = useState<boolean>(true);
  const [fasting, setFasting] = useState<boolean>(false);

  const fetchPatientAndAppointments = async (): Promise<void> => {
    setLoading(true);
    try {
      const patientRequest = await appointmentService.fetchPatient(id);
      const appointmentRequest = await patientService.fetchPatientAppointments(id);
      setPatient(patientRequest.data.data);
      setAppointments(appointmentRequest.data.results);
      setTotalRows(appointmentRequest.data.count);
      setNextRequest(appointmentRequest.data.next.replace("http://", "https://"));
      setPrevRequest(appointmentRequest.data.previous.replace("http://", "https://"));
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  };

  const fetchNewPage = async (action: "next" | "previous"): Promise<void> => {
    setLoading(true);
    try {
      const request = await api.request({
        method: "GET",
        url: action === "next" ? nextRequest : prevRequest,
      });
      setAppointments(request.data.results);
      setTotalRows(request.data.count);
      setNextRequest(request.data.next.replace("http://", "https://"));
      setPrevRequest(request.data.previous.replace("http://", "https://"));
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    const action = currentPage > newPage ? "previous" : "next";
    fetchNewPage(action);
    setCurrentPage(newPage);
  };

  useEffect(() => {
    fetchPatientAndAppointments();
  }, []);

  return (
    <PrivateRoute>
      <LoadingError
        loading={loading}
        error={error}
      />
      {!loading && (
        <>
          <Paper className="text-center p-16">
            <div className="bg-gray-200 rounded-lg p-4 inline-block text-left">
              <div className="flex justify-between mb-4">
                <h3 className="mr-8">Paciente</h3>
              </div>
              <p className="mb-2">
                <span className="font-bold">Primer Nombre: </span> {patient.first_name}
              </p>
              <p className="mb-2">
                <span className="font-bold">Segundo Nombre: </span> {patient.second_name}
              </p>
              <p className="mb-2">
                <span className="font-bold">Primer Apellido: </span> {patient.last_name}
              </p>
              <p className="mb-2">
                <span className="font-bold">Segundo Apellido: </span> {patient.second_last_name}
              </p>
              <p className="mb-2">
                {(patient.document_type === "ci" || patient.document_type === undefined) && <span className="font-bold">RUT: </span>}
                {patient.document_type === "passport" && <span className="font-bold">Pasaporte: </span>}
                {patient.document_number}
              </p>
              <p className="mb-2">
                <span className="font-bold">Sexo: </span> {patient.gender === "male" ? "Masculino" : "Femenino"}
              </p>
              <p className="mb-2">
                <span className="font-bold">Teléfono: </span> {patient.phone}
              </p>
              <p className="mb-2">
                <span className="font-bold">Email: </span> {patient.email}
              </p>
              <p className="mb-2">
                <span className="font-bold">Edad: </span> {calculateAge(patient.date_of_birth)} años
              </p>
              <p className="mb-2">
                <span className="font-bold">Fecha de nacimiento: </span> {moment.utc(patient.date_of_birth).format("DD [de] MMMM [de] YYYY")}
              </p>
              <div className="flex justify-end mt-4">
                <Link to="/patients">
                  <Button
                    variant="text"
                    color="primary"
                    className="float-right m-5"
                  >
                    Volver
                  </Button>
                </Link>
                <Link to={`/patients/${patient.id}/edit`}>
                  <Button
                    variant="outlined"
                    color="primary"
                    className="float-right m-5"
                  >
                    Editar
                  </Button>
                </Link>
              </div>
            </div>
          </Paper>
          <div className="flex justify-around my-8">
            <FormControlLabel
              label="Ayuno"
              control={
                <Switch
                  color="primary"
                  checked={fasting}
                  onChange={(e) => {
                    setFasting(e.target.checked);
                  }}
                />
              }
            />
            <FormControlLabel
              label="SMS"
              control={
                <Switch
                  color="primary"
                  checked={sms}
                  onChange={(e) => {
                    setSms(e.target.checked);
                  }}
                />
              }
            />
          </div>
          <DashboardGrid
            appointments={appointments}
            sms={sms}
            fasting={fasting}
            totalRows={totalRows}
            currentPage={currentPage}
            handleChangePage={handleChangePage}
          />
        </>
      )}
    </PrivateRoute>
  );
};

export default IndexPage;
